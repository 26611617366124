import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
// import { Link } from "gatsby"

const Content = styled.div`
  text-align: left;
`;

const TermsOfSale = () => {
  return (
    <Layout>
      <h1>Terms of Sale</h1>
      <Content>
        <h3>SmartScaleSync Memberships</h3>
        <p>
          If you purchase a membership you acknowledge that your membership will
          auto renew until you cancel your membership or we terminate it. You
          can cancel your subscription at any time by logging into your account
          and clicking on the "manage subscription" link at the bottom of the
          billing section. This will take you to the customer billing portal
          where you will be able to cancel your subscription. If you cancel your
          subscription your membership will remain active until the end of your
          current billing period. You will not have the right to receive any
          pro-rated refunds if you cancel your membership period early. You must
          cancel your membership at least 24 hours before the end of each
          billing cycle in order to avoid being billed for the next billing
          cycle's membership. With an active SmartScaleSync membership the
          service will sync new weighins as you complete them (weighins
          completed before your membership began are not synced). Service
          Interuptions: The connections that SmartScaleSync makes with other
          fitness apps might fail as those apps make changes over time. These
          changes are out of our control but we will do our best to restore
          service asap. In the event that we are unable to restore service you
          are not entitled to a refund.
        </p>
        <h3>Refunds for first time memberships</h3>
        <p>
          First time customers have a 7-day money back guarantee on any
          membership plan. If you are not 100% satisfied within 7 days please{" "}
          <a href="mailto:support@smartscalesync.com)">email us</a> and will
          give you a full refund. This policy is for memberships only and does
          not apply to our weighin history sync services.
        </p>
        <h3>Withings-to-Garmin and Fitbit-to-Garmin Weighin History Syncs</h3>
        <p>
          You will be charged a one time non-refundable amount to sync your
          weighin history from Withings or Fitbit to Garmin. You will receive an
          email when your full weighin data sync is complete (can take up to 48
          hours).
        </p>
        <h3>Pricing</h3>
        <p>
          Prices for products or services are subject to change at any time, but
          changes will not affect any order for products or services you have
          already placed except that for auto-renewing memberships price changes
          will take effect when indicated in our email notice to you.
        </p>
        <h3>Errors</h3>
        <p>
          In the event of an error, we reserve the right to correct the error
          and revise your order accordingly (which includes charging the correct
          price) or to cancel the order and refund any amount charged.
        </p>
        <h3>No Refunds</h3>
        <p>
          Except to the extent refunds are required by law or as stated in our
          "Refunds for first time memberships" policy (see above), payments are
          nonrefundable and there are no refunds. However, we may, in our sole
          discretion, provide a refund or discount in certain cases.
        </p>
      </Content>
    </Layout>
  );
};

export default TermsOfSale;
